@font-face {
  font-family: 'Playfair Display', serif;
  src: local('Playfair Display'), url(./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display', serif;
  src: local('Playfair Display'), url(./fonts/PlayfairDisplay-VariableFont_wght.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: "cambria","Times New Roman","Arial", "Serif", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
